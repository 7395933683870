<template>
    <div class="catalog-glasses-container">
        <div class="card">
            <DataView :value="data" :layout="layout" :paginator="true" :rows="20" :sortOrder="sortOrder" :sortField="sortField">
                <template #header>
                    <div class="grid grid-nogutter">
                        <div class="col-lg-6 col-md-12" style="text-align: left">
                            Jeżeli na liście nie znajduję się żaden ogrzewacz pasujący do państwa samochodu - zapraszamy do kontaktu, wykonujemy <b> zamówienia indywidualne </b>.
                        </div>
                    </div>
                </template>

                <template #list="slotProps">
                    <div class="col-12">
                        <div class="product-list-item">
                            <img :src="slotProps.data.icon !== 'something' ? require(`@/assets/${slotProps.data.icon}`) : null" alt=""/>
                            <div class="product-list-detail">
                                <div class="product-name">{{slotProps.data.name}}</div>
                                <div class="product-description-list">{{slotProps.data.desc}}</div>
                                <div class="product-description">Napięcie: {{slotProps.data.voltage}}</div>
                            </div>
                            <div class="product-list-action">

                            </div>
                        </div>
                    </div>
                </template>

                <template #grid="slotProps">
                    <div :class="width < 1300 ? 'col-4' : 'col-3'">                 
                        <div class="product-grid-item product-card" style="height: 18rem;">
                            <div class="product-grid-item-content">
                                <img :src="slotProps.data.icon !== 'something' ? require(`@/assets/${slotProps.data.icon}`) : null" alt=""/>
                                
                            </div>
                            <Divider align="center" type="dashed">
                                <div class="product-name">{{slotProps.data.name}} </div>
                            </Divider>
                                <div class="product-description">{{slotProps.data.desc}}</div>
                                <div class="product-description">Napięcie: {{slotProps.data.voltage}}</div>
                            <div class="center">

                            </div>
                        </div>
                    </div>
                </template>
            </DataView>
        </div>   
    </div>
</template>

<script>
    import DataView from 'primevue/dataview';
    import Divider from 'primevue/divider';
    import { useHead } from '@vueuse/head';
    export default {
        components: {
            DataView,
            Divider
        },
        data() {
            return {
                meta: useHead({
                // Can be static or computed
                title: 'Lusterka Adam - Ogrzewacze',
                meta: [
                    {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                    {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                    {name: 'description', content: 'P.P.H.U. Import Eksport ADAM jest producentem części do lusterek samochodowych działającycm aktywnie na rynku od poand 20lat.  W ofercie znajdują się szkła do lusterek, wkłady oraz ogrzewacze.'},
                    {name: 'keywords', content: 'Lusterka, Szkła do lustrerek, Ogrzewacze, Wkłady, Szkła'}
                ]
                }),
                width: window.innerWidth,
                layout: 'grid',
                sortKey: null,
                sortOrder: null,
                sortField: null,
                sortOptions: [
                    {label: 'Price High to Low', value: '!price'},
                    {label: 'Price Low to High', value: 'price'},
                ],          
                data: [
                        { "desc":"Wymiar: 80x105cm", "name": "GB-08", "icon": "heaters/GB08.webp", "voltage":"12V" },
                        { "desc":"Wymiar: 80x140cm", "name": "GB-10", "icon": "heaters/GB10.webp", "voltage":"12V" },
                        { "desc":"Wymiar: 57x100cm", "name": "GB-06", "icon": "heaters/GB6.jpg", "voltage":"12V" },
                        { "desc":"Wymiar: 90x150cm", "name": "GB-16", "icon": "heaters/GB16.webp", "voltage":"12V" },
                        { "desc":"Wymiar: 245x125cm", "name": "GB-02", "icon": "heaters/GB2.webp", "voltage":"12V" },
                        { "desc":"Wymiar: 110x165cm", "name": "GB-11", "icon": "heaters/GB11.jpg", "voltage":"24V" },
                        { "desc":"Wymiar: 95x155cmcm", "name": "GB-7", "icon": "heaters/GB7.jpg", "voltage":"12V" },
                        { "desc":"Wymiar: 340x145cm", "name": "GB-21", "icon": "heaters/GB21.webp", "voltage":"24V" }
                    ]
            }
        },
        methods: {
            onSortChange(event){
                const value = event.value.value;
                const sortValue = event.value;

                if (value.indexOf('!') === 0) {
                    this.sortOrder = -1;
                    this.sortField = value.substring(1, value.length);
                    this.sortKey = sortValue;
                }
                else {
                    this.sortOrder = 1;
                    this.sortField = value;
                    this.sortKey = sortValue;
                }
            },
            getResolution() {
                this.width = window.innerWidth
                if (window.innerWidth < 1000) {
                    this.layout = 'list'
                }
                else {
                    this.layout = 'grid'
                }
            }
        },
        computed: {
            
        },
        mounted() {
            this.getResolution()
            window.addEventListener('resize', () => {
                this.getResolution()
            })
        }
    }
</script>

<style scoped>
.catalog-glasses-container {
    height: 100%;
    background-color: rgb(241, 240, 240);
}
.card {
    background: #fff;
    padding: 2rem;
    
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
	border-radius: 4px;
}
.product-card {
    padding-bottom: 0.5rem;
}
 .p-dropdown {
	width: 14rem;
	font-weight: normal;
}
 .product-name {
	font-size: 1.5rem;
	font-weight: 700;
}
 .product-description {
	display: flex;
    widows: 100%;
    justify-content: center;
}
 .product-category-icon {
	vertical-align: middle;
	margin-right: 0.5rem;
}
 .product-category {
	font-weight: 600;
	vertical-align: middle;
}
 ::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
}
 ::v-deep(.product-list-item) img {
	width: 50px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin-right: 2rem;
}
 ::v-deep(.product-list-item) .product-list-detail {
	flex: 1 1 0;
}
 ::v-deep(.product-list-item) .p-rating {
	margin: 0 0 0.5rem 0;
}
 ::v-deep(.product-list-item) .product-price {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
	align-self: flex-end;
}
 ::v-deep(.product-list-item) .product-list-action {
	display: flex;
	flex-direction: column;
}
 ::v-deep(.product-list-item) .p-button {
	margin-bottom: 0.5rem;
}
 ::v-deep(.product-grid-item) {
	margin: 0.5rem;
	border: 1px solid var(--surface-border);
}
 ::v-deep(.product-grid-item) .product-grid-item-top, ::v-deep(.product-grid-item) .product-grid-item-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
 ::v-deep(.product-grid-item) img {
    width: 100px;
    height: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 2rem 0;
    
}
 ::v-deep(.product-grid-item) .product-grid-item-content {
	text-align: center;
        padding-bottom: 1rem;
}
 ::v-deep(.product-grid-item) .product-price {
	font-size: 1.5rem;
	font-weight: 600;
}
 @media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;
	}
	.product-list-item img {
		margin: 2rem 0;
	}
	.product-list-item .product-list-detail {
		text-align: center;
	}
	.product-list-item .product-price {
		align-self: center;
	}
	.product-list-item .product-list-action {
		display: flex;
        justify-content: center !important;
		flex-direction: column;
	}
	.product-list-item .product-list-action {
		margin-top: 2rem;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
}
.center {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}
.header {
    width: 100%;
    min-height: 2rem;
    padding-top: 1rem !important;
    background-color: rgb(153, 153, 153);
}
.catalog-button {
    background-color: var(--orange10);
    border-color: var(--orange10);
}
.catalog-button:hover {
    background-color: var(--orange20);
    border-color: var(--orange20);
}
.p-divider.p-component.p-divider-horizontal.p-divider-dashed.p-divider-center {
    margin: 0;
}

</style>